<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Date Time</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Date Time"
              >
                <flat-pickr
                  v-model="datetime"
                  class="form-control bg-transparent"
                  :config="{
                    dateFormat: 'd/m/Y h:i K',
                    enableTime: true,
                    time_24hr: false,
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>



          <b-col md="4">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name"
              >
                <b-form-input
                  v-model="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Mobile Number</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Mobile Number"
              >
                <b-form-input
                  type="number"
                  v-model="mobile"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Mobile Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col md="4">
            <b-form-group>
              <label>Address / City</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Address"
              >
                <b-form-textarea
                  v-model="address"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> </b-col
          >
          <b-col md="4">
            <b-form-group>
              <label>No. of person</label>
              <label style="color: red !important">*</label>
                <b-form-input
                  v-model="noofperson"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="No. of person"
                />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Purpose</label>
              <label style="color: red !important">*</label>
              <validation-provider
              #default="{errors}"
              rules="required"
              name="Purpose">
                <b-form-input
                  v-model="purpose"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Purpose"
                />
                <small class="text-danger">{{ errors[0]}}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Whom to meet</label>
              <label style="color: red !important">*</label>
                <b-form-input
                  v-model="whomtomeet"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Whome to meet"
                />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Refrence</label>
              <label style="color: red !important">*</label>
                <b-form-input
                  v-model="refrence"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Meet Porson"
                />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Time Out</label>
                <flat-pickr
                  v-model="timeout"
                  class="form-control bg-transparent"
                  :config="{
                    dateFormat: 'd/m/y h:i K',
                    enableTime: true,
                  }"
                />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Other Details</label>

                <b-form-input
                  v-model="otherdetails"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Other Details"
                />

            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "@/components/axios";
import moment from 'moment'
export default {
  components: {
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,

      flag: false,
      name: "",
      datetime: null,
      address: "",
      purpose: "",
      whomtomeet: "",
      noofperson:'',
      timeout: null,
      refrence:'',
      otherdetails: "",
      mobile:'',
    };
  },
  mounted() {
     const rights = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      rights.map((item) => {
        if (item.modulename == "Register") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/register");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/register");
          }
        }
      });
    }
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },

  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/registerById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
       this.datetime = moment(item.datetime).format('DD/MM/YYYY h:mm A');
        this.name = item.name;
        this.otherdetails = item.otherdetails;
        this.address = item.address,
        this.purpose = item.purpose,
        this.whomtomeet = item.whomtomeet,
        this.noofperson = item.noofperson,
        this.timeout = item.timeout,
        this.mobile = item.mobile;
        this.refrence = item.refrence
      });
    },
    onClickBack() {
      this.$router.go(-1);
    },
    submitForm(e) {
      const data = {
        name: this.name,
        datetime: this.datetime,
        address: this.address,
        purpose: this.purpose,
        whomtomeet: this.whomtomeet,
        noofperson:this.noofperson,
        timeout: this.timeout,
        otherdetails: this.otherdetails,
        refrence : this.refrence,
        mobile:this.mobile,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/register/${this.$route.params.id}`
              : `${baseApi}/register`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.flag = false;

              this.$router.go(-1);
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
